import { defineComponent, h, ref, watch } from 'vue';

import './stepper.scss';

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    length: {
      type: Number,
      default: 3,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const internal = ref(props.modelValue);

    watch(() => props.modelValue, (val: number) => {
      internal.value = val;
    });

    watch(internal, (val) => {
      emit('update:modelValue', val);
    });

    function genDivider() {
      return h('svg', {
        class: 'stepper__divider',
        width: 24,
        height: 1,
        viewBox: '0 0 24 1',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      }, h('line', {
        x1: '0.5',
        y1: '0.5',
        x2: '23.5',
        y2: '0.5',
        stroke: '#9294A3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      }));
    }

    function genItems() {
      return Array(props.length).fill(null).map((_, i) => {
        const children = [h('div', {
          class: {
            stepper__item: true,
            stepper__item_active: i < props.modelValue,
          },
        }, i + 1)];

        if (i + 1 < props.length) {
          children.push(genDivider());
        }

        return children;
      });
    }

    return () => h('div', {
      class: 'stepper',
    }, genItems());
  },
});
