import { defineComponent } from 'vue';
import { IconType, IconName } from 'components/Icon';
import { Size, MainColor } from 'core/styles';
import { useRouter } from 'vue-router';

const AuthLayout = defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    showBackBtn: {
      type: Boolean,
      default: false,
    },
    backBtnTo: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  setup(props) {
    const router = useRouter();
    function back() {
      if (props.backBtnTo) {
        router.push(props.backBtnTo);
      } else {
        router.back();
      }
    }

    return {
      back,

      IconType,
      IconName,
      Size,
      MainColor,
    };
  },
});

export default AuthLayout;
