import { defineComponent, h, ref, watch } from 'vue';
import { useRender } from 'composables/render';

import './code-input.scss';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    length: {
      type: Number,
      default: 4,
    },
    maxWidth: {
      type: Number,
      default: 343,
    },
    err: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'submit'],

  setup(props, { emit }) {
    const rootRef = ref<HTMLElement>();

    const internal = ref(props.modelValue);

    watch(internal, (val) => {
      emit('update:modelValue', val);
    });

    function focus() {
      const first = rootRef.value?.querySelector('.code-input__input');
      if (first) {
        (first as HTMLElement).focus();
      }
    }

    function focusPrev(item: HTMLElement) {
      const prev = item.previousSibling as HTMLElement;
      if (prev) {
        placeCaretAtEnd(prev);
      }
    }

    function focusNext(item: HTMLElement) {
      const next = item.nextSibling as HTMLElement;
      if (next) {
        placeCaretAtEnd(next);
      }
    }

    function placeCaretAtEnd(el: HTMLElement) {
      el.focus();
      if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    }

    function onInput(e: InputEvent, i: number) {
      const target = e.target as HTMLElement;
      const data = e.data || '';
      if (data) {
        target.innerText = e.data as string;
        focusNext(target);
      }
      const arr = internal.value.split('');
      arr.splice(i, 1, data);
      internal.value = arr.join('');
    }

    function genItems() {
      return Array(props.length).fill(null).map((_, i) => h('span', {
        contenteditable: true,
        tabindex: '-1',
        inputmode: 'numeric',
        class: {
          'code-input__input': true,
        },
        onInput: (e: InputEvent) => onInput(e, i),
        onFocus: (e: Event) => {
          const target = e.target as HTMLElement;
          placeCaretAtEnd(target);
        },
        onKeydown: (e: KeyboardEvent) => {
          if (e.key === 'Backspace') {
            const target = e.target as HTMLElement;
            target.innerText = '';
            focusPrev(target);
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            emit('submit');
          }
        },
        onVnodeBeforeMount: (node) => {
          const { el } = node;
          if (el) {
            el.innerHTML = '&nbsp;';
          }
        },
      }));
    }

    useRender(() => h('div', {
      ref: rootRef,
      class: {
        'code-input': true,
        'code-input_error': props.err,
      },
      style: {
        maxWidth: props.maxWidth ? `${props.maxWidth}px` : undefined,
      },
    }, genItems()));

    return {
      rootRef,
      focus,
    };
  },
});
