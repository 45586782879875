<template>
  <main
    key="main"
    :class="{
      'auth-layout': true,
    }"
  >
    <icon-btn
      v-if="showBackBtn"
      class="auth-layout__back"
      :size="Size.M"
      :color="MainColor.Black"
      @click="back"
    >
      <icon
        :type="IconType.Contour"
        :name="IconName.ArrowBack"
        :size="Size.S"
      />
    </icon-btn>

    <router-link
      to="/"
      class="auth-layout-header__logo"
    >
      <logo />
    </router-link>

    <h3
      v-if="title"
      :class="['auth-layout__title', subtitle ? 'mb-2' : 'mb-5']"
    >
      {{ title }}
    </h3>

    <div
      v-if="subtitle"
      class="auth-layout__subtitle"
    >
      {{ subtitle }}
    </div>
    <slot />
    <div class="auth-layout__support">
      <slot name="nav">
        <router-link to="/technical-support">{{ $t('pages.main.technicalSupport') }}</router-link>
      </slot>
    </div>
  </main>
</template>

<script lang="ts" src="./AuthLayout.ts"></script>
<style lang="scss" src="./auth-layout.scss"></style>
